<template>
  <div class="about">
    <h1>This is an about page</h1>
    <UploadImage />
  </div>
</template>
<script>
import UploadImage from '../components/NewUser/UploadImage.vue';
export default {
  name: 'About',
  components: {
    UploadImage
  }
}
</script>